import React, { useContext } from 'react';

import { DataContext } from "../../../contexts/data";
import type { DataContextValueType } from "../../../contexts/data";

import Button from '../../../components/Button';
import Svg from '../../../components/Svg';

type DateAndTimeButtonsProps = {
  handlePrev: () => void;
  handleNext: () => void;
  nextDisabled: boolean;
  buttonsContainerRef: React.RefObject<HTMLDivElement>;
};

const DateAndTimeButtons = ({ handlePrev, handleNext, nextDisabled, buttonsContainerRef }: DateAndTimeButtonsProps) => {
  const {
    isEditMode
  } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="step-container__buttons container" ref={buttonsContainerRef}>
      {!isEditMode && (
        <Button onClick={handlePrev} kind="ghost" leftIcon={<Svg content="arrow-left" />}>
          Back
        </Button>
      )}
      <Button onClick={handleNext} disabled={nextDisabled} rightIcon={<Svg content="double-chevron-right" />}>
        Next: Select Add-ons
      </Button>
    </div>
  );
};

export default DateAndTimeButtons;
